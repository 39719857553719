require('./bootstrap');
import Zoomist from 'zoomist'

(function($) {
	$(document).ready(function(){
		// https://www.npmjs.com/package/zoomist
		const emplazamientos = document.querySelector('#zoom');
		new Zoomist(emplazamientos, {
			fit: 'contain',
			maxRatio: 7,
			height: '1%',
			slider: true,
			zoomer: true
		});
		$('.slider').on('init', function (event, slick) {
			var slides = $(this).find('.slick-slide');
			slides.each(function(){
				$(this).find('.zoom').each(function () {
				    if($(this).hasClass('zoom')){
				    	var id = $(this).attr('id');
				    	const planta = document.querySelector('#'+id);
						new Zoomist(planta, {
							maxRatio: 4,
							height: '0%',
							// slider: true,
							zoomer: true
						});
				    }
				});
			});
		});
		// https://kenwheeler.github.io/slick/
	  	$(".slider").not('.slick-initialized').slick({
		  	dots: false,
		  	arrows: true,
		  	autoplay: false,
		  	infinite: false
		});
	});
}(jQuery));

window.verTerminaciones = function(e) {
	$(e).next().addClass('visible');
}

window.cerrarTerminaciones = function(e) {
	$(e).parent().removeClass('visible');
}

window.irA = function(target, tipologia = null) {
	var extra = $('nav').outerHeight();
  	if (target.length) {
    	$('html,body').animate({
      		scrollTop: $(target).offset().top - extra + 1
    	}, 1000);
  	}
  	if(tipologia) {
  		$('#tipologias .slider').slick('slickGoTo', tipologia - 1);
  	}
}